import { createApp } from 'vue'
import App from './App.vue'

import firebase from 'firebase/compat/app'
import firebaseConfig from '@/config/firebase'

import router from './router'

//need to import below line to use 'store.dispatch()'
import store from './store'

firebase.initializeApp(firebaseConfig)

const cloverapp = createApp(App)
cloverapp.use(router)
cloverapp.use(store)
cloverapp.mount('#app')
