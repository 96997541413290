import PageRegister from '@/pages/PageRegister'
import PageLogin from '@/pages/PageLogin'
import PageHome from '@/pages/PageHome'
import PageNotFound from '@/pages/PageNotFound'
import PageMember from '@/pages/PageMember'
import PageSubscribe from '@/pages/PageSubscribe'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: PageHome
    },
    {
        path: '/register',
        name: 'Register',
        component: PageRegister
    },
    {
        path: '/login',
        name: 'Login',
        component: PageLogin
    },
    {
        path: '/subscribe',
        name: 'Subscribe',
        component: PageSubscribe
    },
    {
        path: '/prediction',
        name: 'Prediction',
        component: PageMember,
        meta: { requiresAuth: true }
    },
    {
        path: '/logout',
        name: 'SignOut',
        async beforeEnter() {
            await store.dispatch('signOut')
            return { name: 'Home' }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: PageNotFound
    }
    // {
    //     path: '/thread:id',
    //     name: 'ThreadShow',
    //     component: ThreadShow,
    //     props: true              => define props: in the component to use
    // }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

//For firestore
router.beforeEach(async (to) => {
    await store.dispatch('initAuthentication')
    store.dispatch('unsubscribeAllSnapshots')
    if (to.meta.requiresAuth) {
        if (!store.state.authId) {
            //console.log("no user, redirect to home")
            return { name: 'Home' }
        } else {
            //console.log("has user")
        }
    }
})

export default router