<template>
    <section class="container">
        <div class="columns is-multiline">
            <div class="column is-4 is-offset-4">
                <div class="columns">
                    <div class="column right has-text-centered">
                        <router-link :to="{ name: 'Home' }"><img src="@/assets/cloverai.png"></router-link>
                        <h1 class="title is-4">CloverAI</h1>
                        <p class="description">Login</p>
                        <form @submit.prevent="signIn">
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.email" class="input is-medium" type="email" placeholder="Email">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.password" class="input is-medium" type="password"
                                        placeholder="Password">
                                </div>
                            </div>
                            <button class="button is-block is-primary is-fullwidth is-medium">Submit</button>
                            <br />
                            <small><em>Not a member?
                                    <router-link :to="{ name: 'Register' }">
                                        Register
                                    </router-link></em></small>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            }
        }
    },
    methods: {
        async signIn() {
            try {
                await this.$store.dispatch('signInWithEmailandPassword', { ...this.form })
                this.$router.push({ name: 'Prediction' })
            } catch (error) {
                alert(error.message)
            }

        }
    }
}
</script>

<style scoped></style>