<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <RouterLink class="navbar-item" :to="{ name: 'Home' }">
                <img src="@/assets/cloverai_logo.png" width="112" height="28">
            </RouterLink>

            <a @click="makeBurger" role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
                data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': activator }">
            <div class="navbar-start">
                <a class="navbar-item">
                    Home
                </a>

                <div class="navbar-item">
                    <RouterLink :to="{ name: 'Subscribe' }">
                        Subscribe
                    </RouterLink>
                </div>

                <div class="navbar-item" v-if="authUser">
                    <RouterLink :to="{ name: 'Prediction' }">
                        Prediction
                    </RouterLink>
                </div>

            </div>

            <div class="navbar-end">
                <div class="navbar-item">

                    <div class="buttons" v-if="!authUser">
                        <RouterLink :to="{ name: 'Register' }" class="button is-primary">
                            <strong>Sign up</strong>
                        </RouterLink>
                        <RouterLink :to="{ name: 'Login' }" class="button is-light">
                            Log in
                        </RouterLink>
                    </div>

                    <div class="buttons" v-else>
                        <RouterLink :to="{ name: 'Register' }">
                            <a @click.prevent="$store.dispatch('signOut')" class="button is-primary">
                                <strong>Sign Out</strong></a>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            activator: false
        }
    },
    computed: {
        ...mapGetters(['authUser'])
    },
    methods: {
        makeBurger() {
            this.activator = !this.activator
            return this.activator
        }
    }
}

</script>

<style scoped></style>