<template>
    <section class="container">
        <div class="columns is-multiline">
            <div class="column is-8 is-offset-2 register">
                <div class="columns">
                    <div class="column left">
                        <router-link :to="{ name: 'Home' }"><img src="@/assets/cloverai.png"></router-link>
                        <h1 class="title is-1">Actionable AI Trading</h1>
                        <h2 class="subtitle colored is-4">Sorry...</h2>
                        <p>Page not found!</p>
                    </div>
                </div>
            </div>
            <div class="column is-8 is-offset-2">
                <br>
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <span class="icon">
                                <i class="fab fa-twitter"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-facebook"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-instagram"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-github"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div class="level-right">
                        <small class="level-item" style="color: var(--textLight)">
                            &copy; CloverAI.app All Rights Reserved.
                        </small>
                    </div>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped></style>