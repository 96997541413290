<template>
    <section class="hero is-primary is-fullheight">
        <div class="hero-body">
            <div class="">
                <p class="title">
                    Subscribtion expired?
                </p>
                <p class="subtitle">
                    1,500 THB / 30 days
                </p>
                <p>
                    Transfer the money to:
                </p>
                <br>
                <p>
                    Bank: SCB
                </p>
                <p>
                    Account Name: CLOVER AI COM
                </p>
                <p>
                    Account Number: 420-124-8625
                </p>
                <br>
                <p>
                    Send an email to 'clover.tradeapps@gmail.com' and attach the deposit slip.
                </p>
                <p>We will enable the access for you within 24 hrs.</p>
                <br>
                <RouterLink :to="{ name: 'Home' }">Back</RouterLink>
            </div>
        </div>
    </section>
</template>