<template>
    <StockList />
</template>
<script>
import StockList from '@/components/StockList.vue'
export default {
    components: {
        StockList
    }
}
</script>