<template>
    <section class="container">
        <div class="columns is-multiline">
            <div class="column is-8 is-offset-2 register">
                <div class="columns">
                    <div class="column left">
                        <router-link :to="{ name: 'Home' }"><img src="@/assets/cloverai.png"></router-link>
                        <h1 class="title is-1">Actionable AI Trading</h1>
                        <h2 class="subtitle colored is-4">You get fresh signals everyday.</h2>
                        <p>We blend AI/ML models to predict BUY/SELL stocks for you to make smart decisions.</p>
                    </div>
                    <div class="column right has-text-centered">
                        <h1 class="title is-4">Sign up today</h1>
                        <p class="description">Get 30 days free access!</p>
                        <form @submit.prevent="register">
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.name" class="input is-medium" type="text" placeholder="Name">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.username" class="input is-medium" type="text"
                                        placeholder="Username">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.email" class="input is-medium" type="email" placeholder="Email">
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <input v-model="form.password" class="input is-medium" type="password"
                                        placeholder="Password">
                                </div>
                            </div>
                            <button class="button is-block is-primary is-fullwidth is-medium">Submit</button>
                            <br />
                            <small><em>Already a member? <router-link
                                        :to="{ name: 'Login' }">Login</router-link></em></small>
                        </form>
                    </div>
                </div>
            </div>
            <div class="column is-8 is-offset-2">
                <br>
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <span class="icon">
                                <i class="fab fa-twitter"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-facebook"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-instagram"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-github"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div class="level-right">
                        <small class="level-item" style="color: var(--textLight)">
                            &copy; CloverAI.app All Rights Reserved.
                        </small>
                    </div>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
export default {
    data() {
        return {
            form: {
                name: '',
                username: '',
                email: '',
                password: '',
            }
        }
    },
    methods: {
        async register() {
            //console.log('submmited form', this.form)
            const result = await firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)
            //console.log(result)
            await this.$store.dispatch('createUser', { id: result.user.uid, ...this.form })
            //console.log(result.user.uid)
            this.$router.push({ name: 'Prediction' })
        }
    }
}
</script>

<style scoped></style>