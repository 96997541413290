import { createStore } from 'vuex'
//import sourceData from '@/data.json'
import { upsert } from '@/helpers'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export default createStore({
    state: {
        //resources
        users: [],
        // we need to update below from Firebase
        authId: null,
        authUserUnsubscribe: null,
        unsubscribes: [],
        authObserverUnsubscribe: null
    },
    getters: {
        authUser: state => state.users.find(user => user.id === state.authId)
    },
    actions: { //fet API but never update state = methods in component

        async fetchStocks() {

        },
        initAuthentication({ dispatch, commit }) {
            if (this.state.authObserverUnsubscribe) this.state.authObserverUnsubscribe()
            return new Promise((resolve) => {
                //console.log('in InitAuthentication - call back')
                const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
                    if (user) {
                        //console.log('in main - dispatch fetAuthUser')
                        await dispatch('fetchAuthUser')
                        resolve(user)
                    } else {
                        resolve(null)
                    }

                })
                commit('setAuthObserverUnsubscribe', unsubscribe)
            })

        },
        async createUser({ commit }, { id, name, username, email }) {
            //console.log(name, username, email)
            const registeredAt = firebase.firestore.FieldValue.serverTimestamp()
            username = username.toLowerCase()
            email = email.toLowerCase()
            const today_date = new Date()
            const valid_until = new Date(today_date.setMonth(today_date.getMonth() + 1))
            //console.log(valid_until)
            const firestore_valid_until = firebase.firestore.Timestamp.fromDate(valid_until)

            const user = { name, username, email, registeredAt, valid_until: firestore_valid_until }
            const userRef = await firebase.firestore().collection('users').doc(id)
            userRef.set(user)
            const newUser = await userRef.get()

            //console.log(newUser)
            //console.log(newUser.data(), newUser.id)
            commit('setUser', { resources: 'users', item: newUser })
            return newUser
        },
        //Full detail of users in firestore
        fetchUser({ commit }, { id }) {
            //console.log('in fetchUser id: ', id)
            return new Promise((resolve) => {
                const unsubscribe = firebase.firestore().collection('users').doc(id).onSnapshot((doc) => {
                    const user = { ...doc.data(), id: doc.id }
                    //console.log('in fetchUser doc.data(): ', doc.data())
                    commit('setUser', { resources: 'users', item: user })
                    //console.log('in fetchUser user: ', user)
                    resolve(user)
                })
                commit('appendUnsubsribe', { unsubscribe })
            })
        },
        async fetchAuthUser({ commit, dispatch }) {
            const userId = firebase.auth().currentUser?.uid
            //console.log('in fethAuthUser userId: ', userId)
            if (!userId) return

            await dispatch('fetchUser', { id: userId })
            //Set mutation
            commit('setAuthId', userId)
        },
        async signInWithEmailandPassword(context, { email, password }) {
            return firebase.auth().signInWithEmailAndPassword(email, password)
        },
        async signOut({ commit }) {
            await firebase.auth().signOut()
            commit('setAuthId', null)
        },
        async unsubscribeAllSnapshots({ state, commit }) {
            state.unsubscribes.forEach(unsubsribe => unsubsribe())
            commit('clearAllUnsubscribes')
        }

    },
    mutations: { // update states
        setAiModels(state, stocks) {
            // update state
            state.aimodel = stocks
        },
        setUser(state, { resources, item }) {
            //console.log('setUser: ', item)
            upsert(state[resources], item)
            //console.log('state user: ', state.users)
        },
        setAuthId(state, id) {
            //console.log('Set authId = id', id)
            state.authId = id
        },
        setAuthUserUnsubscribe(state, unsubscribe) {
            state.authUserUnsubscribe = unsubscribe
        },
        appendUnsubsribe(state, { unsubscribe }) {
            state.unsubscribes.push(unsubscribe)
        },
        clearAllUnsubscribes(state) {
            state.unsubscribes = []
        },
        setAuthObserverUnsubscribe(state, unsubscribe) {
            //console.log('setAuthObserverUnsubscribe', unsubscribe)
            state.authObserverUnsubscribe = unsubscribe
        }
    }
})