<template>
    <section v-if="aimodels" class="container">
        <div class="columns is-multiline">
            <div class="column is-8 is-offset-2">
                <div class="columns">
                    <div class="column left">
                        <router-link :to="{ name: 'Home' }"><img src="@/assets/cloverai.png"></router-link>

                        <h1 class="title is-1">AI @ {{ diffForHumans(aimodels.createdAt.seconds) }}</h1>
                        <h2 class="subtitle is-4">BUY/LONG</h2>
                        <div class="buttons">
                            <button class="button is-primary is-rounded" v-for="stock in aimodels.buy" :key="stock">
                                {{ stock }}
                            </button>
                        </div>
                        <h2 class="subtitle is-4">SELL/SHORT</h2>
                        <div class="buttons">
                            <button class="button is-warning is-rounded" v-for="stock in aimodels.sell" :key="stock">
                                {{ stock }}
                            </button>
                        </div>

                        <h3>{{ authUser?.name }}, member valid until: {{
                            humanDate(authUser?.valid_until.seconds) }}
                        </h3>
                        <router-link :to="{ name: 'SignOut' }" class="button is-danger is-light is-small is-rounded">
                            Sign Out</router-link>

                    </div>
                </div>
            </div>
            <div class="column is-8 is-offset-2">
                <br>
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <span class="icon">
                                <i class="fab fa-twitter"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-facebook"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-instagram"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fab fa-github"></i>
                            </span> &emsp;
                            <span class="icon">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div class="level-right">
                        <small class="level-item" style="color: var(--textLight)">
                            &copy; CloverAI.app All Rights Reserved.
                        </small>
                    </div>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
import daysjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
daysjs.extend(relativeTime)

import firebaseConfig from '@/config/firebase'
import { initializeApp } from "firebase/app";
const app = initializeApp(firebaseConfig);
import { getFirestore } from "firebase/firestore";
const db = getFirestore(app);
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";

import { mapGetters } from 'vuex'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            predictions: []
        }
    },
    computed: {
        aimodels() {
            return this.predictions.slice(-1)[0]
        },
        ...mapGetters(['authUser'])
    },
    async beforeCreate() {
        // get all rows
        // const querySnapshot = await getDocs(collection(db, "predictions"));
        // querySnapshot.forEach((doc) => {
        //     console.log(doc.id, " => ", doc.data());
        //     this.predictions.push(doc.data())
        // });

        // get only one row
        const predictionsRef = collection(db, "predictions")
        const q = query(predictionsRef, orderBy("createdAt", "desc"), limit(1))
        const querySnapshot = await getDocs(q);
        //console.log('query data from firestore')
        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data())
            this.predictions.push(doc.data())
        })
    },
    methods: {
        diffForHumans(timestamp) {
            return daysjs.unix(timestamp).fromNow()
        },
        humanDate(timestamp) {
            return dayjs.unix(timestamp).format('DD/MM/YYYY')
        }
    },
    mounted() {
        //console.log("Mounted hook")
        //console.log(this.authUser.valid_until.seconds * 1000)
        //console.log(new Date().getTime())
        if (this.authUser.valid_until.seconds * 1000 > new Date().getTime()) {
            //console.log("Member ok")
        } else {
            //console.log("Member expired")
            this.$router.push({ name: 'Subscribe' })
        }
    }
}
</script>

<style scoped></style>